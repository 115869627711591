<template>
  <v-app>
    <!--las toolbar-->
    <ToolBar />
    
    <v-main>
      <!--las rutas-->
      <router-view />
    </v-main>

    <!--las footer-->
    <Footer />
  </v-app>
</template>

<script>
import ToolBar from "@/components/ToolBar.vue";
import Footer from "@/components/Footer.vue";
import Vue from "vue";
import axios from "axios";

export default {
  name: "App",

  components: {
    ToolBar,
    Footer,
  },

  mounted() {
    console.log(
      "Página creada con vue, vuetify, axios, flipbook-vue... by J. Carlos M. A."
    );

    axios.get("/php/api.php?variable=recientes").then((response) => {
      Vue.prototype.$trabajosRecientes = response.data.items;
      //console.log(response.data.items);
    });

    axios.get("/php/api.php?variable=destacados").then((response) => {
      Vue.prototype.$trabajosDestacados = response.data.items;
    });

    axios.get("/php/api.php?variable=carrucel").then((response) => {
      Vue.prototype.$imagenesCarrucel = response.data.items;
      //console.log(response.data.items);
    });
    /*
    axios.get("/php/api.php?variable=carrucelLaser").then((response) => {
      Vue.prototype.$carrucelLaser = response.data;
    });
    */
  },
};
</script>