import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'
import ServicioDeMaquinado from '../views/ServicioDeMaquinado.vue'
import ServicioDeImm from '../views/ServicioDeImm.vue'
import ServicioDeCorte from '../views/ServicioDeCorte.vue'
import Nosotros from '../views/Nosotros.vue'
import TrabajosRecientes from '../views/TrabajosRecientes.vue'
import TrabajosDestacados from '../views/TrabajosDestacados.vue'
import Componentes from '../views/Componentes.vue'
import Contacto from '../views/Contacto.vue'
import Catalogo from '../views/Catalogo.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Inicio',
      component: Inicio
    },
    {
      path: '/servicioDeMaquinado',
      name: 'ServicioDeMaquinado',
      component: ServicioDeMaquinado
    },
    {
      path: '/servicioDeImm',
      name: 'ServicioDeImm',
      component: ServicioDeImm
    },
    {
      path: '/servicioDeCorte',
      name: 'ServicioDeCorte',
      component: ServicioDeCorte
    },
    {
      path: '/nosotros',
      name: 'Nosotros',
      component: Nosotros
    },
    {
      path: '/trabajosRecientes',
      name: 'TrabajosRecientes',
      component: TrabajosRecientes
    },
    {
      path: '/trabajosDestacados',
      name: 'TrabajosDestacados',
      component: TrabajosDestacados
    },
    {
      path: '/componentes',
      name: 'Componentes',
      component: Componentes
    },
    {
      path: '/contacto',
      name: 'Contacto',
      component: Contacto
    },
    {
      path: '/catalogo',
      name: 'Catalogo',
      component: Catalogo
    },
    { path: "*", component: PageNotFound }
  ]
})

export default router
