<template>
  <div>
    <v-btn color="#002550" @click="mailto" dark>
      <v-icon left dark> mdi-email </v-icon>
      Contacto
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "btnContacto",

  methods: {
    mailto() {
      window.open("mailto:ventas@conveyors-immsa.com?Subject=Cotización");
    },
  },
};
</script>