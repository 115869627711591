<template>
  <div class="Nosotros">
    <v-container>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="9" md="10">
          <v-card class="cardMarca" flat>
            <v-row class="fill-height" align="center" justify="center">
              <v-card flat>
                <v-card-title class="text-h5 font-weight-medium">
                  Nuestra marca
                </v-card-title>
              </v-card>
              <v-card flat>
                <v-card-text class="black--text font-weight-regular">
                  <v-btn class="mx-2" fab dark small color="#002655">
                    <v-icon dark> mdi-star-outline </v-icon>
                  </v-btn>
                  Somos una empresa que ha desarrollado producto propio y marca
                  propia:
                </v-card-text>
              </v-card>
              <v-card flat>
                <v-card-title>
                  <v-img
                    max-height="100"
                    max-width="150"
                    lazy-src="@/assets/PrecargaLazzy.svg"
                    src="/img/ovalflexLogo.svg"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card-title>
              </v-card>
            </v-row>
          </v-card>

          <v-expansion-panels class="cardMarca" elevation="0" v-model="panel">
            <v-expansion-panel v-for="(item, i) in items" :key="i">
              <v-expansion-panel-header color="#f8f8f8">
                {{ item.titulo }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-justify">
                {{ item.texto }}
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header color="#f8f8f8">
                Nuestra filosofia
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="margin-bottom: 4rem">
                  <div class="text-h6 text-center">MISION</div>
                  <v-row>
                    <v-col md="5"> </v-col>
                    <v-col md="3">
                      <v-img
                        class="imagCursor"
                        lazy-src="@/assets/PrecargaLazzy.svg"
                        height="100"
                        width="100"
                        src="/img/mvv/mision.svg"
                      ></v-img>
                    </v-col>
                    <v-col md="4"> </v-col>
                  </v-row>

                  <v-row>
                    Proveer equipos de manejo de materiales de excelencia en
                    funcionamiento y calidad, que les permita a nuestros
                    clientes mejorar la productividad de sus procesos.
                  </v-row>
                </div>

                <div style="margin-bottom: 4rem">
                  <div class="text-h6 text-center">VISION</div>
                  <v-row>
                    <v-col md="5"> </v-col>
                    <v-col md="3">
                      <v-img
                        class="imagCursor"
                        lazy-src="@/assets/PrecargaLazzy.svg"
                        height="100"
                        width="100"
                        src="/img/mvv/vision.svg"
                      ></v-img>
                    </v-col>
                    <v-col md="4"> </v-col>
                  </v-row>

                  <v-row>
                    Ser una empresa de clase mundial, logrando un
                    posicionamiento en la mente de nuestros clientes.
                  </v-row>
                </div>

                <div>
                  <div class="text-h6 text-center">VALORES</div>
                  <v-row>
                    <v-col md="5"> </v-col>
                    <v-col md="3">
                      <v-img
                        class="imagCursor"
                        lazy-src="@/assets/PrecargaLazzy.svg"
                        height="100"
                        width="100"
                        src="/img/mvv/valores.jpg"
                      ></v-img>
                    </v-col>
                    <v-col md="4"> </v-col>
                  </v-row>

                  <v-row>
                    <p>
                      • <b>Compromiso</b> (Ir más allá de la responsabilidad,
                      diferencia entre involucrado y comprometido). Compromiso
                      de trabajo
                      <br />
                      • <b>Calidad</b> (Hacer las cosas bien a la primera, poner
                      atención y cuidado. En otras palabras “ser” bien hecho).
                      <br />
                      • <b>Honestidad</b> (Que no nos gane la codicia y la
                      ambición. Vale más un trabajo para toda la vida que un
                      golpe de suerte).
                      <br />
                      • <b>Trabajo en equipo</b> (Trabajar para el bien común)
                    </p>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Nosotros",

  components: {},

  mounted() {},

  data: () => ({
    //Desplegables
    items: [
      {
        titulo: "Nosotros",
        texto:
          "Es una empresa especializada en manejo de materiales, que nace aportando soluciones a sus clientes, ofertando toda una gama de servicios que permita, mediante una continua filosofía de innovación, elevar el nivel de competitividad de las organizaciones que demande nuestra colaboración, prestándole especial atención a sus necesidades consiguiéndolo con el esfuerzo de nuestros profesionales.",
      },
      {
        titulo: "Nuestra historia",
        texto: `En el año 2003 IMMSA inicia actividades de ensamble para el manejo de materiales, 
                            con solamente un cliente: GKN Driveline, teniendo como base un pequeño establecimiento que 
                            servía de oficina administrativa y taller. Después de un tiempo y gracias al buen servicio brindado, 
                            la empresa logra captar más clientes y ocupar la planta del servicio de mantenimiento en GKN.
                            Con las nuevas reglamentaciones por parte del principal cliente, surgió la necesidad de convertirse en una empresa S.A. 
                            por lo que en el 2012 se realizan los trámites necesarios para registrarse como: “CONVEYORS IMMSA MEXICO S.A. DE C.V.”

                            En el 2015 gracias al desempeño y a la suma de todos los esfuerzos realizados, la empresa adquiere sus instalaciones ubicadas en Lic. J. Víctor Lizardi, dónde actualmente reside. 
                            Desde sus indexs la filosofía de trabajo de la empresa está basada en la fidelidad, calidad, precios bajos y trabajo en equipo. El recurso humano con el que cuenta la empresa, ha ayudado a la consecuencia del cumplimiento de los objetivos planteados por gerencia.
                        `,
      },
    ],
    panel: 2,
  }),
};
</script>

<style>
.cardMarca {
  margin: 2rem;
}
</style>
