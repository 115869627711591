<template>
  <div class="ToolBar">
    <v-toolbar height="150">
      <v-spacer></v-spacer>

      <router-link to="/" class="link">
        <v-toolbar-title>
          <v-img src="@/assets/logo.svg" lazy-src="@/assets/PrecargaLazzy.svg">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template> </v-img
        ></v-toolbar-title>
      </router-link>

      <v-spacer></v-spacer>

      <div class="hidden-sm-and-down">
        <router-link to="/" class="link texto mr-6">
          <v-btn dark color="#002655" :text="!activeInicio">
            <v-icon dark left>mdi-home</v-icon>
            Inicio
          </v-btn>
        </router-link>

        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="texto mr-6"
              color="#002655"
              dark
              :text="!activeServicios"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark left>mdi-cog</v-icon>
              Servicios
              <v-icon dark right>{{
                chevron ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <router-link
              v-for="item in itemsServicios"
              :key="item.id"
              :to="item.link"
              class="link"
            >
              <v-list-item class="itemDesplegable">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list>
        </v-menu>

        <router-link to="/nosotros" class="link texto mr-6">
          <v-btn dark color="#002655" :text="!activeNosotros">
            <v-icon dark left>mdi-account-group</v-icon>
            Nosotros
          </v-btn>
        </router-link>

        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="texto mr-6"
              color="#002655"
              dark
              :text="!activePortafolio"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark left>mdi-briefcase-variant</v-icon>
              Portafolio
              <v-icon dark right>{{
                chevron ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <router-link
              v-for="item in itemsPortafolio"
              :key="item.id"
              :to="item.link"
              class="link"
            >
              <v-list-item
                class="itemDesplegable"
                @click="activo('portafolio')"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list>
        </v-menu>

        <router-link to="/contacto" class="link texto mr-6">
          <v-btn dark color="#002655" :text="!activeContacto">
            <v-icon dark left>mdi-account-group</v-icon>
            Contacto
          </v-btn>
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <div class="hidden-md-and-up">
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" dark color="#002655" v-bind="attrs" v-on="on">
              <v-icon dark> mdi-menu </v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <router-link
              v-for="item in items"
              :key="item.id"
              :to="item.link"
              class="link"
            >
              <v-list-item class="itemDesplegable">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list>
        </v-menu>
      </div>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  name: "ToolBar",

  data: () => ({
    // Data
    activeInicio: true,
    activeNosotros: false,
    activeContacto: false,
    activeServicios: false,
    activePortafolio: false,

    itemsServicios: [
      { id: 1, title: "Servicio De Maquinado", link: "/servicioDeMaquinado" },
      {
        id: 2,
        title: "Ingenieria en Manejo de Materiales",
        link: "/servicioDeImm",
      },
      { id: 3, title: "Servicio De Corte Laser", link: "/servicioDeCorte" },
    ],

    itemsPortafolio: [
      { id: 1, title: "Trabajos Recientes", link: "/trabajosRecientes" },
      { id: 2, title: "Trabajos Destacados", link: "/trabajosDestacados" },
      //{ id: 3, title: "Catalogo", link: "/componentes" },
    ],

    items: [
      { id: 1, title: "Inicio", link: "/" },
      { id: 2, title: "Servicio De Maquinado", link: "/servicioDeMaquinado" },
      {
        id: 3,
        title: "Ingenieria en Manejo de Materiales",
        link: "/servicioDeImm",
      },
      { id: 4, title: "Servicio De Corte Laser", link: "/servicioDeCorte" },
      { id: 5, title: "Nosotros", link: "/nosotros" },
      { id: 6, title: "Trabajos Recientes", link: "/trabajosRecientes" },
      { id: 7, title: "Trabajos Destacados", link: "/trabajosDestacados" },
      //{ id: 8, title: "Catalogo", link: "/componentes" },
      { id: 9, title: "Contacto", link: "/contacto" },
    ],
    chevron: false,
  }),

  mounted() {
    this.activo(this.$route.name);
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  watch: {
    currentRouteName(val) {
      this.activo(val);
    },
  },

  methods: {
    activo(btn) {
      if (btn === "Inicio") {
        this.activeInicio = true;
        this.activeNosotros = false;
        this.activeContacto = false;
        this.activeServicios = false;
        this.activePortafolio = false;
      }

      if (btn === "Nosotros") {
        this.activeNosotros = true;
        this.activeInicio = false;
        this.activeContacto = false;
        this.activeServicios = false;
        this.activePortafolio = false;
      }

      if (btn === "Contacto") {
        this.activeContacto = true;
        this.activeInicio = false;
        this.activeNosotros = false;
        this.activeServicios = false;
        this.activePortafolio = false;
      }

      if (
        btn === "ServicioDeMaquinado" ||
        btn === "ServicioDeImm" ||
        btn === "ServicioDeCorte"
      ) {
        this.activeContacto = false;
        this.activeInicio = false;
        this.activeNosotros = false;
        this.activeServicios = true;
        this.activePortafolio = false;
      }

      if (
        btn === "TrabajosRecientes" ||
        btn === "TrabajosDestacados" ||
        btn === "Componentes"
      ) {
        this.activeContacto = false;
        this.activeInicio = false;
        this.activeNosotros = false;
        this.activeServicios = false;
        this.activePortafolio = true;
      }
    },
  },
};
</script>

<style>
.link {
  text-decoration: none;
}

.texto {
  font-family: Arial, Helvetica, sans-serif;
}

.itemDesplegable {
  background-color: #002655;
  color: #fff !important;
  border-bottom: 1px dotted white;
}

.itemDesplegable:hover {
  cursor: pointer;
  background-color: rgb(1, 1, 20);
}
</style>