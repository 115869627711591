<template>
  <div class="Componentes2">
    <v-row>
      <v-banner color="#002550" prominent dark width="100%">
        Componentes
      </v-banner>
    </v-row>
    <v-row class="mt-12 fill-height botonTop" align="center" justify="center">
      <v-btn width="150" @click="closeAll()">
        {{ abrirCerrarTexto }}
      </v-btn>
      <v-spacer></v-spacer>

      <!--<Busqueda :buscar="buscar" @update-buscar="buscado"></Busqueda>-->

      <v-text-field
        style="margin-right: 2rem; margin-top: 1rem"
        outlined
        label="Busqueda"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
      >
      </v-text-field>

      <v-img
        max-height="100"
        max-width="150"
        lazy-src="@/assets/PrecargaLazzy.svg"
        src="/img/ovalflexLogo.svg"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-spacer></v-spacer>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      group-by="categoriaorden"
      :items-per-page="-1"
      hide-default-header
      :footer-props="{
        'items-per-page-text': 'Elementos por pagina',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}',
      }"
      :search="search"
      class="elevation-0 tabla"
    >
      <template
        v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
      >
        <th
          :colspan="headers.length"
          @click="toggle"
          :data-open="isOpen"
          style="background-color: white"
        >
          <v-col>
            <v-row align="center">
              <v-btn small icon :ref="group" :data-open="isOpen">
                <v-icon dense>{{
                  isOpen ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
              <img
                v-show="!isOpen"
                :src="items[0].imagen"
                height="100px"
                class="margenes"
              />
              <h3>{{ items[0].categoria }}</h3>
            </v-row>
          </v-col>
        </th>
      </template>

      <template v-slot:[`item.nombre`]="{ item }">
        <v-card class="cardComponent" width="100%" flat>
          <div
            class="titulo"
            style="background-color: #03244f40; color: #03244f"
          >
            <p style="margin: 0" class="font-weight-bold">
              {{ item.nombre }}
            </p>
          </div>

          <v-card-text style="border-top: #002655 solid 2px !important">
            <v-row>
              <v-col class="px-0" cols="12" md="8">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-img
                    class="imag"
                    @click="detalles(item)"
                    :src="item.imagen"
                    width="400"
                  ></v-img>
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-card flat>
                  <v-card-text>
                    <p style="margin-bottom: 0">
                      No. Parte: <b>{{ item.noParte }}</b>
                    </p>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <p style="margin-bottom: 0">Caracteristicas:</p>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text> {{ item.descripcion }} </v-card-text>
                </v-card>
                <v-card flat>
                  <v-card-text>
                    <v-img :src="item.imagenCotas" width="250"></v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>

    <Progress :cargando="cargando"></Progress>
    <!--Dialog que muestra los detalles-->
    <DialogDetallesFull
      :dialog="dialog"
      :nombreComponente="nombreComponente"
      :imagenesDetalles="imagenesDetalles"
      @update-cerrar="update"
    ></DialogDetallesFull>
  </div>
</template>
<script>
import Progress from "@/components/Progress.vue";
import DialogDetallesFull from "@/components/DialogDetallesFull.vue";
export default {
  name: "Componentes2",

  components: {
    Progress,
    DialogDetallesFull,
  },

  data: () => ({
    //items
    items: [
      {
        id: 1,
        imagen: "/img/componentes/unidad.jpg",
        descripcion: "",
        categoria: "UNIDADES DE TRANSMISIÓN",
        categoriaorden: 1,
        nombre: "UNIDAD MOTRIZ OVALFLEX PARA PERFIL 100-85 MM CADENA 83 MM",
        imagenCotas: "/img/componentes/cotas/1.jpg",
        noParte: "OVUM-335-195-100",
      },
      {
        id: 2,
        imagen: "/img/componentes/unidad retorno.jpg",
        descripcion: "",
        categoria: "UNIDADES DE RETORNO",
        categoriaorden: 2,
        nombre: "UNIDAD DE RETORNO OVALFLEX PARA PERFIL 100-85 MM CADENA 83 MM",
        imagenCotas: "/img/componentes/cotas/2.jpg",
        noParte: "OVUR-335-195-100",
      },
      {
        id: 3,
        imagen: "/img/componentes/curvaEmbalerada.jpg",
        descripcion: "",
        categoria: "CURVAS HORIZONTALES EMBALERADAS",
        categoriaorden: 3,
        nombre: "CURVA EMBALERADA 90° RADIO REDUCIDO R=200",
        imagenCotas: "/img/componentes/cotas/3.jpg",
        noParte: "OVCE-100-85-90°",
      },
      {
        id: 4,
        imagen: "/img/componentes/curvaEmbalerada60.jpg",
        descripcion: "",
        categoria: "CURVAS HORIZONTALES EMBALERADAS",
        categoriaorden: 3,
        nombre: "CURVA EMBALERADA 60° RADIO REDUCIDO R=200",
        imagenCotas: "/img/componentes/cotas/4.jpg",
        noParte: "OVCE-100-85-60°",
      },
      {
        id: 5,
        imagen: "/img/componentes/curvaEmbalerada45.jpg",
        descripcion: "",
        categoria: "CURVAS HORIZONTALES EMBALERADAS",
        categoriaorden: 3,
        nombre: "CURVA EMBALERADA 45° RADIO REDUCIDO R=200",
        imagenCotas: "",
        noParte: "OVCE-100-85-45°",
      },
      {
        id: 6,
        imagen: "/img/componentes/curvaEmbalerada30.jpg",
        descripcion: "",
        categoria: "CURVAS HORIZONTALES EMBALERADAS",
        categoriaorden: 3,
        nombre: "CURVA EMBALERADA 30° RADIO REDUCIDO R=200",
        imagenCotas: "",
        noParte: "OVCE-100-85-30°",
      },
      {
        id: 7,
        imagen: "/img/componentes/curva 90 2.jpg",
        descripcion: "",
        categoria: "CURVAS HORIZONTALES PLANAS",
        categoriaorden: 4,
        nombre: "CURVA PLANA 90° RADIO REDUCIDO R=200",
        imagenCotas: "",
        noParte: "OVCP-200-100-85-90°",
      },
      {
        id: 8,
        imagen: "/img/componentes/curva 60.jpg",
        descripcion: "",
        categoria: "CURVAS HORIZONTALES PLANAS",
        categoriaorden: 4,
        nombre: "CURVA PLANA 60° RADIO REDUCIDO R=200",
        imagenCotas: "",
        noParte: "OVCP-200-100-85-60°",
      },
      {
        id: 9,
        imagen: "/img/componentes/curva 45.jpg",
        descripcion: "",
        categoria: "CURVAS HORIZONTALES PLANAS",
        categoriaorden: 4,
        nombre: "CURVA PLANA 45° RADIO REDUCIDO R=200",
        imagenCotas: "",
        noParte: "OVCP-200-100-85-45°",
      },
      {
        id: 10,
        imagen: "/img/componentes/curva 30.jpg",
        descripcion: "",
        categoria: "CURVAS HORIZONTALES PLANAS",
        categoriaorden: 4,
        nombre: "CURVA PLANA 30° RADIO REDUCIDO R=200",
        imagenCotas: "",
        noParte: "OVCP-200-100-85-30°",
      },
      {
        id: 11,
        imagen: "",
        descripcion: "",
        categoria: "CURVAS VERTICALES",
        categoriaorden: 5,
        nombre: "",
        imagenCotas: "",
        noParte: "",
      },
      {
        id: 12,
        imagen: "/img/componentes/perfilOval.jpg",
        descripcion: "",
        categoria: "PERFIL DE TRANSPORTADOR",
        categoriaorden: 6,
        nombre: "PERFIL DE TRANSPORTADOR 100X85 MM OVAL FLEX",
        imagenCotas: "/img/componentes/cotas/11.jpg",
        noParte: "OVPT-3000-100-85",
      },
      {
        id: 13,
        imagen: "/img/componentes/perfil130.jpg",
        descripcion: "",
        categoria: "PERFIL DE TRANSPORTADOR",
        categoriaorden: 6,
        nombre: "PERFIL DE TRANSPORTADOR 130x85 MM OVAL FLEX",
        imagenCotas: "/img/componentes/cotas/12.jpg",
        noParte: "OVPT-3000-130-85",
      },
      {
        id: 14,
        imagen: "/img/componentes/perfil200.jpg",
        descripcion: "",
        categoria: "PERFIL DE TRANSPORTADOR",
        categoriaorden: 6,
        nombre: "PERFIL DE TRANSPORTADOR 200x85 MM OVAL FLEX",
        imagenCotas: "/img/componentes/cotas/13.jpg",
        noParte: "OVPT-3000-200-85",
      },
      {
        id: 15,
        imagen: "/img/componentes/guia de desgaste.jpg",
        descripcion: "",
        categoria: "GUÍAS DE DESGASTE",
        categoriaorden: 7,
        nombre: "GUIA DE DESGASTE 5.5 MM DE ESPESOR CORTADA A 3/4",
        imagenCotas: "",
        noParte: "OVGD-3050-19.05-13",
      },
      {
        id: 16,
        imagen: "/img/componentes/perfil oval 2.jpg",
        descripcion: "",
        categoria: "SOPORTERÍA DE TRANSPORTADOR",
        categoriaorden: 8,
        nombre: "PERFIL DE SOPORTE 80x80 OVAL",
        imagenCotas: "/img/componentes/cotas/15.jpg",
        noParte: "OVPS-6000-80-80",
      },
      {
        id: 17,
        imagen: "/img/componentes/perfil oval 3.jpg",
        descripcion: "",
        categoria: "SOPORTERÍA DE TRANSPORTADOR",
        categoriaorden: 8,
        nombre: "PERFIL SOPORTE 40X40 LIGERO OVAL",
        imagenCotas: "/img/componentes/cotas/16.jpg",
        noParte: "OVPS-6000-40-40-L",
      },
      {
        id: 18,
        imagen: "/img/componentes/bracket de soporte normal.jpg",
        descripcion: "",
        categoria: "SOPORTERÍA DE TRANSPORTADOR",
        categoriaorden: 8,
        nombre:
          "BRACKET DE SOPORTE PARA PERFIL DE TRANSPORTADOR OVAL 100X85 MM",
        imagenCotas: "/img/componentes/cotas/17.jpg",
        noParte: "OVBS-205-70-47",
      },
      {
        id: 19,
        imagen: "/img/componentes/bracket de soporte.jpg",
        descripcion: "",
        categoria: "SOPORTERÍA DE TRANSPORTADOR",
        categoriaorden: 8,
        nombre:
          "BRACKET DE SOPORTE PARA PERFIL DE TRANSPORTADOR OVAL 100X85 MM TIPO PUENTE",
        imagenCotas: "/img/componentes/cotas/18.jpg",
        noParte: "OVBS-156-70-60-TP",
      },
      {
        id: 20,
        imagen: "/img/componentes/base oval 80.jpg",
        descripcion: "",
        categoria: "SOPORTERÍA DE TRANSPORTADOR",
        categoriaorden: 8,
        nombre: "BASE DE ACERO PARA PERFIL OVAL 80X80 MM",
        imagenCotas: "/img/componentes/cotas/19.jpg",
        noParte: "OVBA-250-250-160",
      },
      {
        id: 21,
        imagen: "/img/componentes/base oval.jpg",
        descripcion: "",
        categoria: "SOPORTERÍA DE TRANSPORTADOR",
        categoriaorden: 8,
        nombre: "BASE DE ACERO PARA PERFIL OVAL 40X40 MM",
        imagenCotas: "/img/componentes/cotas/20.jpg",
        noParte: "OVBA-150-150-120",
      },
      {
        id: 22,
        imagen: "/img/componentes/ancla1.jpg",
        descripcion: "",
        categoria: "SOPORTERÍA DE TRANSPORTADOR",
        categoriaorden: 8,
        nombre: "ANCLAS ACERO AL CARBON 1/4 KIT",
        imagenCotas: "",
        noParte: "OVBS-250-80-A",
      },
      {
        id: 23,
        imagen: "/img/componentes/ancla2.jpg",
        descripcion: "",
        categoria: "SOPORTERÍA DE TRANSPORTADOR",
        categoriaorden: 8,
        nombre: "ANCLAS ACERO AL CARBON 1/4 KIT",
        imagenCotas: "",
        noParte: "OVBS-250-80-A",
      },
      {
        id: 83,
        imagen: "/img/componentes/guia acero.jpg",
        descripcion: "",
        categoria: "GUÍAS LATERALES",
        categoriaorden: 9,
        nombre:
          'GUIA LATERAL ACERO ZINKADO (TRAMO DE 1,8 MTS) INCLUYE BARRENOS 1/4 X 2"',
        imagenCotas: "/img/componentes/cotas/23.svg",
        noParte: "OVGL-1800-50.8-6.35",
      },
      {
        id: 24,
        imagen: "/img/componentes/guia.jpg",
        descripcion: "",
        categoria: "GUÍAS LATERALES",
        categoriaorden: 9,
        nombre:
          'GUIA LATERAL ACERO ZINKADO (TRAMO DE 1,8 MTS) INCLUYE BARRENOS 1/4 x 1"',
        imagenCotas: "/img/componentes/cotas/24.svg",
        noParte: "OVGL-1800-25.4-6.35",
      },
      {
        id: 25,
        imagen: "/img/componentes/guia interior 90.jpg",
        descripcion: "",
        categoria: "GUÍAS LATERALES",
        categoriaorden: 9,
        nombre: 'JUEGO DE GUÍAS EXTERIOR E INTERIOR 90° DE 1"',
        imagenCotas: "/img/componentes/cotas/25.jpg",
        noParte: "OVGL-90°25.4-6.35",
      },
      {
        id: 26,
        imagen: "/img/componentes/guia interior 60.jpg",
        descripcion: "",
        categoria: "GUÍAS LATERALES",
        categoriaorden: 9,
        nombre: 'JUEGO DE GUÍAS EXTERIOR E INTERIOR 60° DE 1"',
        imagenCotas: "",
        noParte: "OVGL-60°25.4-6.35",
      },
      {
        id: 27,
        imagen: "/img/componentes/guia interior 45.jpg",
        descripcion: "",
        categoria: "GUÍAS LATERALES",
        categoriaorden: 9,
        nombre: 'JUEGO DE GUÍAS EXTERIOR E INTERIOR 45° DE 1"',
        imagenCotas: "/img/componentes/cotas/27.jpg",
        noParte: "OVGL-45°25.4-6.35",
      },
      {
        id: 28,
        imagen: "/img/componentes/guia interior 30.jpg",
        descripcion: "",
        categoria: "GUÍAS LATERALES",
        categoriaorden: 9,
        nombre: 'JUEGO DE GUÍAS EXTERIOR E INTERIOR 30° DE 1"',
        imagenCotas: "",
        noParte: "OVGL-30°25.4-6.35",
      },
      {
        id: 29,
        imagen: "/img/componentes/Solera3.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERA DE EMPALME GUIA LATERAL ANCHO 25 MM",
        imagenCotas: "/img/componentes/cotas/29.jpg",
        noParte: "OVSE-80-25-4.7",
      },
      {
        id: 30,
        imagen: "/img/componentes/Solera4.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERA DE EMPALME GUIA LATERAL ANCHO 17 MM",
        imagenCotas: "/img/componentes/cotas/30.jpg",
        noParte: "OVSE-80-17-4.7",
      },
      {
        id: 31,
        imagen: "/img/componentes/Solera5.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERA DE EMPALME GUIA LATERAL ANCHO 13 MM",
        imagenCotas: "/img/componentes/cotas/31.jpg",
        noParte: "OVSE-80-13-4.7",
      },
      {
        id: 32,
        imagen: "/img/componentes/Solera2.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "Solera",
        imagenCotas: "/img/componentes/cotas/32.jpg",
        noParte: "",
      },
      {
        id: 33,
        imagen: "/img/componentes/Solera6.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERAS DE EMPALME DE TRANSPORTADOR OVAL 20° VERTICAL",
        imagenCotas: "/img/componentes/cotas/33.jpg",
        noParte: "OVSE-160-25.4-6.35-20°-V",
      },
      {
        id: 34,
        imagen: "/img/componentes/Solera7.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERAS DE EMPALME DE TRANSPORTADOR OVAL 15° VERTICAL",
        imagenCotas: "/img/componentes/cotas/34.jpg",
        noParte: "OVSE-160-25.4-6.35-15°-V",
      },
      {
        id: 35,
        imagen: "/img/componentes/Solera8.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERAS DE EMPALME DE TRANSPORTADOR OVAL 10° VERTICAL",
        imagenCotas: "/img/componentes/cotas/35.jpg",
        noParte: "OVSE-160-25.4-6.35-10°-V",
      },
      {
        id: 36,
        imagen: "/img/componentes/Solera9.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERAS DE EMPALME DE TRANSPORTADOR OVAL 5° VERTICAL",
        imagenCotas: "/img/componentes/cotas/36.jpg",
        noParte: "OVSE-160-25.4-6.35-5°-V",
      },
      {
        id: 37,
        imagen: "/img/componentes/Solera10.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERAS DE EMPALME DE TRANSPORTADOR OVAL 20° HORIZONTAL",
        imagenCotas: "",
        noParte: "OVSE-160-25.4-6.35-20°-H",
      },
      {
        id: 38,
        imagen: "/img/componentes/Solera11.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERAS DE EMPALME DE TRANSPORTADOR OVAL 15° HORIZONTAL",
        imagenCotas: "",
        noParte: "OVSE-160-25.4-6.35-15°-H",
      },
      {
        id: 39,
        imagen: "/img/componentes/Solera12.jpg",
        descripcion: "",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        nombre: "SOLERAS DE EMPALME DE TRANSPORTADOR OVAL 10° HORIZONTAL",
        imagenCotas: "",
        noParte: "OVSE-160-25.4-6.35-10°-H",
      },
      {
        id: 40,
        imagen: "/img/componentes/Solera13.jpg",
        categoria: "SOLERAS DE EMPALME",
        categoriaorden: 10,
        descripcion: "",
        nombre: "SOLERAS DE EMPALME DE TRANSPORTADOR OVAL 5º HORIZONTAL",
        imagenCotas: "",
        noParte: "OVSE-160-25.4-6.35-5°-H",
      },
      {
        id: 41,
        imagen: "/img/componentes/brida motoreductor.jpg",
        descripcion: "",
        categoria: "BRIDAS DE MOTORES",
        categoriaorden: 11,
        nombre: "BRIDA MOTOREDUCTOR 150:1 1/4 HP",
        imagenCotas: "/img/componentes/cotas/41.jpg",
        noParte: "OVBM-152-152-4.7",
      },
      {
        id: 80,
        imagen: "/img/componentes/guarda1.jpg",
        descripcion: "",
        categoria: "GUARDAS DE TRANSMISIÓN Y RETORNO",
        categoriaorden: 12,
        nombre: "GUARDA DE TRANSMISIÓN Y/O RETORNO PARA TRANSPORTADOR DOBLE",
        imagenCotas: "/img/componentes/cotas/80.jpg",
        noParte: "OVGT-215-194-107",
      },
      {
        id: 81,
        imagen: "/img/componentes/guarda2.jpg",
        descripcion: "",
        categoria: "GUARDAS DE TRANSMISIÓN Y RETORNO",
        categoriaorden: 12,
        nombre: "GUARDA DE TRANSMISIÓN Y/O RETORNO PERFIL 85 MM",
        imagenCotas: "/img/componentes/cotas/81.jpg",
        noParte: "OVGT-194-107-3",
      },
      {
        id: 82,
        imagen: "/img/componentes/guarda3.jpg",
        descripcion: "",
        categoria: "GUARDAS DE TRANSMISIÓN Y RETORNO",
        categoriaorden: 12,
        nombre: "GUARDA DE TRANSMISION Y RETORNO MESA DE ALIMENTACION",
        imagenCotas: "/img/componentes/cotas/82.jpg",
        noParte: "OVGT-625-85-MA",
      },
      {
        id: 42,
        imagen: "/img/componentes/soporte compuesto400.jpg",
        descripcion: "",
        categoria: "BRACKETS DE GUIA LATERAL",
        categoriaorden: 13,
        nombre: "BRACKETS DE GUIA LATERAL PARA PTR TAMAÑO 400 MM",
        imagenCotas: "/img/componentes/cotas/42.jpg",
        noParte: "OVBG-400-38-PTR",
      },
      {
        id: 43,
        imagen: "/img/componentes/soporte compuesto300.jpg",
        descripcion: "",
        categoria: "BRACKETS DE GUIA LATERAL",
        categoriaorden: 13,
        nombre: "BRACKETS DE GUIA LATERAL PARA PTR TAMAÑO 300 MM",
        imagenCotas: "/img/componentes/cotas/43.jpg",
        noParte: "OVBG-300-38-PTR",
      },
      {
        id: 44,
        imagen: "/img/componentes/soporte compuesto250.jpg",
        descripcion: "",
        categoria: "BRACKETS DE GUIA LATERAL",
        categoriaorden: 13,
        nombre: "BRACKET DE GUIA LATERAL PARA PTR TAMAÑO 250 MM",
        imagenCotas: "/img/componentes/cotas/44.jpg",
        noParte: "OVBG-250-38-PTR",
      },
      {
        id: 45,
        imagen: "/img/componentes/soporte compuesto.jpg",
        descripcion: "",
        categoria: "BRACKETS DE GUIA LATERAL",
        categoriaorden: 13,
        nombre: "BRACKET DE GUIA LATERAL PARA PTR TAMAÑO 100 MM",
        imagenCotas: "/img/componentes/cotas/45.jpg",
        noParte: "OVBG-100-38-PTR",
      },
      {
        id: 46,
        imagen: "/img/componentes/soporte compuesto palanca1.jpg",
        descripcion: "",
        categoria: "BRACKETS DE GUIA LATERAL",
        categoriaorden: 13,
        nombre: "BRACKET DE GUIA LATERAL DE ACERO CON PALANCA M8 TAMAÑO 120 MM",
        imagenCotas: "/img/componentes/cotas/46.jpg",
        noParte: "OVBG-120-38-P-M8",
      },
      {
        id: 47,
        imagen: "/img/componentes/soporte compuesto palanca2.jpg",
        descripcion: "",
        categoria: "BRACKETS DE GUIA LATERAL",
        categoriaorden: 13,
        nombre: "BRACKET DE GUIA LATERAL DE ACERO CON PALANCA M8 TAMAÑO 100 MM",
        imagenCotas: "/img/componentes/cotas/47.jpg",
        noParte: "OVBG-100-38-P-M8",
      },
      {
        id: 101,
        imagen: "",
        descripcion: "",
        categoria: "DOSIFICADORES",
        categoriaorden: 14,
        nombre: "",
        imagenCotas: "",
        noParte: "",
      },
      {
        id: 102,
        imagen: "",
        descripcion: "",
        categoria: "PORTASENSORES",
        categoriaorden: 15,
        nombre: "",
        imagenCotas: "",
        noParte: "",
      },
      {
        id: 50,
        imagen: "/img/componentes/topes.jpg",
        descripcion: "",
        categoria: "TOPES MECANICOS",
        categoriaorden: 16,
        nombre: "TOPES",
        imagenCotas: "/img/componentes/cotas/50.jpg",
        noParte: "",
      },
      {
        id: 63,
        imagen: "/img/componentes/topes2.jpg",
        descripcion: "",
        categoria: "TOPES MECANICOS",
        categoriaorden: 16,
        nombre: "TOPES",
        imagenCotas: "/img/componentes/cotas/63.jpg",
        noParte: "",
      },
      {
        id: 103,
        imagen: "",
        descripcion: "",
        categoria: "ACCESORIOS",
        categoriaorden: 17,
        nombre: "",
        imagenCotas: "",
        noParte: "",
      },
      {
        id: 48,
        imagen: "/img/componentes/charola.jpg",
        descripcion: "",
        categoria: "CHAROLAS RECEPTORAS DE LIQUIDOS",
        categoriaorden: 18,
        nombre: "CHAROLA RECEPTORA DE PERFIL DE ALUMINIO 145 MM",
        imagenCotas: "/img/componentes/cotas/48.jpg",
        noParte: "OVCR-PA-145-3",
      },
      {
        id: 51,
        imagen: "/img/componentes/charola flujo izquierdo.jpg",
        descripcion: "",
        categoria: "CHAROLAS RECEPTORAS DE LIQUIDOS",
        categoriaorden: 18,
        nombre: "CHAROLA FLUJO IZQUIERDO",
        imagenCotas: "/img/componentes/cotas/51.jpg",
        noParte: "",
      },
      {
        id: 52,
        imagen: "/img/componentes/charola flujo derecho.jpg",
        descripcion: "",
        categoria: "CHAROLAS RECEPTORAS DE LIQUIDOS",
        categoriaorden: 18,
        nombre: "CHAROLA FLUJO DERECHO",
        imagenCotas: "/img/componentes/cotas/52.jpg",
        noParte: "",
      },
      {
        id: 54,
        imagen: "/img/componentes/charola oval2.jpg",
        descripcion: "",
        categoria: "CHAROLAS RECEPTORAS DE LIQUIDOS",
        categoriaorden: 18,
        nombre: "CHAROLA RECEPTORA TERMINAL PARA RETORNO MINI",
        imagenCotas: "/img/componentes/cotas/54.jpg",
        noParte: "",
      },
      {
        id: 55,
        imagen: "/img/componentes/brida t.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "BRIDA T",
        imagenCotas: "",
        noParte: "",
      },
      {
        id: 104,
        imagen: "/img/componentes/OVSE-80-25-4.7-AR-C.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "OVSE-80-25-4.7-AR-C",
        imagenCotas: "/img/componentes/cotas/83.jpg",
        noParte: "OVSE-80-25-4.7-AR-C",
      },
      {
        id: 84,
        imagen: "/img/componentes/SoleraCubierta.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "SOLERA PARA CUBIERTA DE CURVA EMBALERDA",
        imagenCotas: "/img/componentes/cotas/84.jpg",
        noParte: "",
      },
      {
        id: 56,
        imagen: "/img/componentes/porta sensor.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "PORTA SENSOR",
        imagenCotas: "/img/componentes/cotas/54.jpg",
        noParte: "",
      },
      {
        id: 57,
        imagen: "/img/componentes/solera oval.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "SOLERA",
        imagenCotas: "/img/componentes/cotas/57.jpg",
        noParte: "",
      },
      {
        id: 59,
        imagen: "/img/componentes/pieza2.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "PIEZA",
        imagenCotas: "/img/componentes/cotas/59.jpg",
        noParte: "",
      },
      {
        id: 60,
        imagen: "/img/componentes/brida doble.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "PIEZA",
        imagenCotas: "/img/componentes/cotas/60.jpg",
        noParte: "",
      },
      {
        id: 61,
        imagen: "/img/componentes/brida ovbu.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "PIEZA",
        imagenCotas: "/img/componentes/cotas/61.jpg",
        noParte: "",
      },
      {
        id: 62,
        imagen: "/img/componentes/dosificador individual.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "DOSIFICADOR INDIVIDUAL",
        imagenCotas: "/img/componentes/cotas/62.jpg",
        noParte: "",
      },
      {
        id: 64,
        imagen: "/img/componentes/tapa perfil.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Tapa de perfil 40",
        imagenCotas: "/img/componentes/cotas/64.jpg",
        noParte: "",
      },
      {
        id: 65,
        imagen: "/img/componentes/angulo de perfil.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Angulo de perfil",
        imagenCotas: "/img/componentes/cotas/65.jpg",
        noParte: "",
      },
      {
        id: 66,
        imagen: "/img/componentes/perilla triangular.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Perilla triangular",
        imagenCotas: "/img/componentes/cotas/66.jpg",
        noParte: "",
      },
      {
        id: 67,
        imagen: "/img/componentes/base de dosificador.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Base de dosificador",
        imagenCotas: "/img/componentes/cotas/67.jpg",
        noParte: "",
      },
      {
        id: 68,
        imagen: "/img/componentes/base de dosificador3.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Base de dosificador",
        imagenCotas: "/img/componentes/cotas/68.jpg",
        noParte: "",
      },
      {
        id: 69,
        imagen: "/img/componentes/collarin.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Collarin",
        imagenCotas: "/img/componentes/cotas/69.jpg",
        noParte: "",
      },
      {
        id: 70,
        imagen: "/img/componentes/pieza1.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Pieza",
        imagenCotas: "/img/componentes/cotas/70.jpg",
        noParte: "",
      },
      {
        id: 71,
        imagen: "/img/componentes/pieza1.2.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Pieza",
        imagenCotas: "/img/componentes/cotas/71.jpg",
        noParte: "",
      },
      {
        id: 72,
        imagen: "/img/componentes/pieza1.3.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Pieza",
        imagenCotas: "/img/componentes/cotas/72.jpg",
        noParte: "",
      },
      {
        id: 73,
        imagen: "/img/componentes/ensambleDosificador.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Ensamble",
        imagenCotas: "/img/componentes/cotas/73.jpg",
        noParte: "",
      },
      {
        id: 74,
        imagen: "/img/componentes/dosificador.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Ensamble",
        imagenCotas: "/img/componentes/cotas/74.jpg",
        noParte: "",
      },
      {
        id: 75,
        imagen: "/img/componentes/OVTR-391.57-295-1.5.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "OVTR-391.57-295-1.5",
        imagenCotas: "/img/componentes/cotas/75.jpg",
        noParte: "",
      },
      {
        id: 76,
        imagen: "/img/componentes/OVTR-391.57-295-1.5-2.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "OVTR-391.57-295-1.5-2",
        imagenCotas: "/img/componentes/cotas/76.jpg",
        noParte: "",
      },
      {
        id: 77,
        imagen: "/img/componentes/OVTT-420-320-4.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "OVTT-420-320-4.76",
        imagenCotas: "/img/componentes/cotas/77.jpg",
        noParte: "",
      },
      {
        id: 78,
        imagen: "/img/componentes/OVTT-420-320-4-2.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "OVTT-418-320-4.76",
        imagenCotas: "/img/componentes/cotas/78.jpg",
        noParte: "",
      },
      {
        id: 79,
        imagen: "/img/componentes/OVLB-1406.7-370.99-1.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "OVLB-1406.7-370.99-1.5",
        imagenCotas: "/img/componentes/cotas/79.jpg",
        noParte: "",
      },
      {
        id: 105,
        imagen: "/img/componentes/bridas T.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Brida T",
        imagenCotas: "",
        noParte: "",
      },
      {
        id: 106,
        imagen: "/img/componentes/sujetador doblado.jpg",
        descripcion: "",
        categoria: "PIEZAS",
        categoriaorden: 19,
        nombre: "Sujetador",
        imagenCotas: "",
        noParte: "",
      },
    ],
    imagenesDetalles: "",
    nombreComponente: "",
    cargando: true,
    search: "",

    //
    abrirCerrar: false,
    abrirCerrarTexto: "cerrar",

    //Dialog detalles
    dialog: false,
  }),
  computed: {
    headers() {
      return [
        { text: "Nombre", value: "nombre", align: "left cardComponent" },
        { text: "noParte", value: "noParte", align: " d-none" },
        { text: "descripcion", value: "descripcion", align: " d-none" },
        { text: "categoria", value: "categoria", align: " d-none" },
      ];
    },
  },
  methods: {
    detalles(item) {
      this.nombreComponente = item.nombre;
      this.dialog = true;
      this.imagenesDetalles = item.imagen;
      //console.log(this.imagenesDetalles);
    },
    update(cerrar) {
      this.dialog = cerrar;
    },

    collapseAll() {
      Object.keys(this.$refs).forEach((k) => {
        //console.log(this.$refs[k])
        this.$refs[k].$el.click();
      });
    },

    closeAll() {
      if (this.abrirCerrar) {
        this.abrirCerrarTexto = "cerrar";

        Object.keys(this.$refs).forEach((k) => {
          if (this.$refs[k] && !this.$refs[k].$attrs["data-open"]) {
            this.$refs[k].$el.click();
            //console.log("this.abrirCerrar");
          }
        });
        this.abrirCerrar = false;
      } else {
        this.abrirCerrarTexto = "abrir";
        Object.keys(this.$refs).forEach((k) => {
          //console.log(this.$refs[k])
          if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
            this.$refs[k].$el.click();
          }
        });
        this.abrirCerrar = true;
      }
    },
  },

  mounted() {
    /*
    axios.get("/php/api.php?variable=lista").then((response) => {
      console.log(response.data);
    });
    //console.log(this.items);
    //this.collapseAll();
    setTimeout(() => {
      this.cargando = false;
    }, 3000);
    */
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

/*
.botonTop {
  margin: 0;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 99;
  background-color: white;
}
*/

.cardComponent {
  margin: 0 !important;
  padding: 0 !important;
  /*
  border: #002655 solid 2px !important;
  */
}
/*
td {
  border-bottom: thin solid rgba(255, 255, 255) !important;
}

th {
  border-bottom: thin solid rgba(255, 255, 255) !important;
}
*/

.tabla {
  margin-left: 10% !important;
  margin-right: 10% !important;
  margin-bottom: 5% !important;
  margin-top: 5% !important;
}

.titulo {
  padding: 1rem !important;
  width: 50%;
}

.imag:hover {
  cursor: pointer;
}

.margenes {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
}
</style>