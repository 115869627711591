<template>
  <v-progress-linear
    height="30"
    style="margin-bottom: 2rem"
    v-show="cargando"
    indeterminate
    color="primary"
    >Cargando...
  </v-progress-linear>
</template>

<script>
export default {
  props: {
    cargando: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    cerrar() {
      this.$emit("update-cerrar", false);
    },
  },
};
</script>