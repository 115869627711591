<template>
  <div class="Catalogo">
    <Flipbook
      class="flipbook"
      :pages="pages"
      :clickToZoom="false"
      v-slot="flipbook"
    >
      <v-row class="fill-width" align="center" justify="center">
        <div style="padding-bottom: 2rem">
          <button id="izquierda" @click="flipbook.flipLeft">
            <v-btn class="mx-2" fab dark small color="primary">
              <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>
          </button>
          {{
            flipbook.numPages === flipbook.page
              ? flipbook.page - 1
              : flipbook.page
          }}
          de
          {{ flipbook.numPages - 1 }}
          <button id="derecha" @click="flipbook.flipRight">
            <v-btn class="mx-2" fab dark small color="primary">
              <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
          </button>
        </div>
      </v-row>
    </Flipbook>

    <v-row class="fill-width" align="center" justify="end">
      <div class="descargaCatalogo">
        <v-row>
          <v-btn fab dark small color="#002550" @click="descargaCatalogo">
            <v-icon dark> mdi-download </v-icon>
          </v-btn>
          <div
            class="text-h6 text-center descargaTexto"
            @click="descargaCatalogo"
          >
            Descargar en PDF
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
import Flipbook from "flipbook-vue";
export default {
  components: {
    Flipbook,
  },
  data: () => {
    return {
      pages: [
        null,
        "/flipBook/1.png",
        "/flipBook/2.png",
        "/flipBook/3.png",
        "/flipBook/4.png",
        "/flipBook/5.png",
        "/flipBook/6.png",
        "/flipBook/7.png",
        "/flipBook/8.png",
        "/flipBook/9.png",
        "/flipBook/10.png",
        "/flipBook/11.png",
        "/flipBook/12.png",
        "/flipBook/13.png",
        "/flipBook/14.png",
        "/flipBook/15.png",
        "/flipBook/16.png",
        "/flipBook/17.png",
        "/flipBook/18.png",
        "/flipBook/19.png",
        "/flipBook/20.png",
      ],
    };
  },
  mounted() {
    window.scroll(0, 0);
    setTimeout(() => {
      document.getElementById("derecha").click();
    }, 750);
  },

  methods: {
    descargaCatalogo() {
      var link = document.createElement("a");
      link.href = "/flipBook/Brochure CONVEYORS IMMSA MEXICO v-3.pdf";
      link.download = "/flipBook/Brochure CONVEYORS IMMSA MEXICO v-3.pdf";
      link.dispatchEvent(new MouseEvent("click"));
    },
  },
};
</script>

<style>
.flipbook {
  margin-top: 2rem;
  margin-bottom: 4rem;
  width: 100vw;
  height: calc(100vh - 230px);
}

.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

.descargaCatalogo {
  margin: 4rem;
}

.descargaTexto {
  margin-left: 1rem;
}
.descargaCatalogo:hover .descargaTexto {
  border-bottom: 2px solid #002550;
}
</style>
