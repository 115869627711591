<template>
  <v-carousel :height="height" :interval="interval" cycle show-arrows-on-hover>
    <v-carousel-item v-for="item in items" :key="item.src">
      <v-img lazy-src="@/assets/PrecargaLazzy.svg" :src="item.src"> </v-img>
      <BotonProyectos v-show="btnHtml"></BotonProyectos>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import BotonProyectos from "@/components/BotonProyectos.vue";
export default {
  data: () => ({
    interval: 5,
  }),
  props: {
    items: {
      type: Array,
      default: [],
    },
    btnHtml: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BotonProyectos,
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 700;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.interval = 5000;
    }, 50);
  },
};
</script>

<style>
.textoBtn {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #dbce13 !important;
}
</style>