<template>
  <v-dialog :value="dialog" @input="$emit('update-cerrar')" width="70%" height="80hv">
    <v-card flat>
      <v-card-title class="text-h5 fondoAzulLetraBlanca">
        {{ nombreComponente }}
        <v-spacer></v-spacer>
        <v-icon color="white" @click="cerrar"> mdi-close </v-icon>
      </v-card-title>

      <v-card-text class="pl-0">
                <v-img lazy-src="@/assets/PrecargaLazzy.svg" :src="imagenesDetalles">
                </v-img>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cerrar"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BotonCorreo from "@/components/BotonCorreo.vue";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    nombreComponente: {
      type: String,
      default: "",
    },
    imagenesDetalles: {
      type: String,
      default: "",
    },
  },
  components: {
    //usa componentes
    BotonCorreo,
  },
  methods: {
    cerrar() {
    this.$emit("update-cerrar", false);
    },
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 300;
        case "lg":
          return 450;
        case "xl":
          return 450;
      }
    },
  },
};
</script>

<style>
.fondoAzulLetraBlanca {
  background-color: #002655 !important;
  color: white !important;
}
</style>