<template>
  <div class="servicioDeMaquinado">
      <v-row no-gutters class="mt-12">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="8" md="8">
          <v-card class="cardMaquinado" flat>
            <v-row style="padding: 2rem">
              <v-col cols="12" sm="12" md="6">
                <p class="texto1" style="text-align: center">•Maquinado•</p>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-img
                          lazy-src="@/assets/PrecargaLazzy.svg"
                          max-height="75"
                          max-width="75"
                          src="/img/canalla.svg"
                        ></v-img>
                        <v-spacer></v-spacer>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
          <br />
          <v-card flat>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="7">
                <p class="texto2" style="text-align: justify">
                  Nosotros contamos con un centro de maquinado (CNC), cuyo
                  servicio ponemos a tu disposición. Con el que garantizamos que
                  podrás obtener piezas con gran precisión y calidad.
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-img
                    style="margin-top: 2rem"
                    lazy-src="@/assets/PrecargaLazzy.svg"
                    max-height="500"
                    max-width="300"
                    src="/img/cnc.png"
                  ></v-img>
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br />

          <v-row>
            <v-spacer></v-spacer>
            <v-img
              lazy-src="@/assets/PrecargaLazzy.svg"
              :width="imagenWidth"
              :src="imagenCalidad"
            ></v-img>
            <v-spacer></v-spacer>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-img
              style="margin-top: 2rem"
              lazy-src="@/assets/PrecargaLazzy.svg"
              max-height="500"
              max-width="300"
              src="/img/equipo.png"
            ></v-img>
            <v-spacer></v-spacer>
          </v-row>

          <br />
          
          <v-row style="margin-bottom: 2rem">
            <v-spacer></v-spacer>
            <BotonCorreo></BotonCorreo>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
  </div>
</template>

<script>
// @ importa componentes despues de esta linea
import BotonCorreo from "@/components/BotonCorreo.vue";
export default {
  name: "servicioDeMaquinado",
  components: {
    //usa componentes
    BotonCorreo,
  },
  data() {
    return {};
  },
  computed: {
    imagenCalidad() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "/img/calidadMedium.svg";
        case "sm":
          return "/img/calidadMedium.svg";
        case "md":
          return "/img/calidadMedium.svg";
        case "lg":
          return "/img/calidadGrande.svg";
        case "xl":
          return "/img/calidadGrande.svg";
      }
    },

    imagenWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300";
        case "sm":
          return "300";
        case "md":
          return "500";
        case "lg":
          return "800";
        case "xl":
          return "800";
      }
    },
  },
};
</script>

<style>
.cardMaquinado {
  border-left: 5px solid #002655 !important;
  background-color: #dddddd !important;
  margin-top: 1rem !important;
}

.texto1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  margin: 2rem;
}

.texto2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem !important;
  margin: 2rem !important;
}
</style>