var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Componentes2"},[_c('v-row',[_c('v-banner',{attrs:{"color":"#002550","prominent":"","dark":"","width":"100%"}},[_vm._v(" Componentes ")])],1),_c('v-row',{staticClass:"mt-12 fill-height botonTop",attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"width":"150"},on:{"click":function($event){return _vm.closeAll()}}},[_vm._v(" "+_vm._s(_vm.abrirCerrarTexto)+" ")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"margin-right":"2rem","margin-top":"1rem"},attrs:{"outlined":"","label":"Busqueda","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-img',{attrs:{"max-height":"100","max-width":"150","lazy-src":require("@/assets/PrecargaLazzy.svg"),"src":"/img/ovalflexLogo.svg"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}})],1)]},proxy:true}])}),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-0 tabla",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","group-by":"categoriaorden","items-per-page":-1,"hide-default-header":"","footer-props":{
      'items-per-page-text': 'Elementos por pagina',
      'items-per-page-all-text': 'Todos',
      'page-text': '{0}-{1} de {2}',
    },"search":_vm.search},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
    var group = ref.group;
    var headers = ref.headers;
    var toggle = ref.toggle;
    var isOpen = ref.isOpen;
    var items = ref.items;
return [_c('th',{staticStyle:{"background-color":"white"},attrs:{"colspan":headers.length,"data-open":isOpen},on:{"click":toggle}},[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-btn',{ref:group,attrs:{"small":"","icon":"","data-open":isOpen}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down"))])],1),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"margenes",attrs:{"src":items[0].imagen,"height":"100px"}}),_c('h3',[_vm._v(_vm._s(items[0].categoria))])],1)],1)],1)]}},{key:"item.nombre",fn:function(ref){
    var item = ref.item;
return [_c('v-card',{staticClass:"cardComponent",attrs:{"width":"100%","flat":""}},[_c('div',{staticClass:"titulo",staticStyle:{"background-color":"#03244f40","color":"#03244f"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(item.nombre)+" ")])]),_c('v-card-text',{staticStyle:{"border-top":"#002655 solid 2px !important"}},[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-spacer'),_c('v-img',{staticClass:"imag",attrs:{"src":item.imagen,"width":"400"},on:{"click":function($event){return _vm.detalles(item)}}}),_c('v-spacer')],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticStyle:{"margin-bottom":"0"}},[_vm._v(" No. Parte: "),_c('b',[_vm._v(_vm._s(item.noParte))])])]),_c('v-divider'),_c('v-card-text',[_c('p',{staticStyle:{"margin-bottom":"0"}},[_vm._v("Caracteristicas:")])]),_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(item.descripcion)+" ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-img',{attrs:{"src":item.imagenCotas,"width":"250"}})],1)],1)],1)],1)],1)],1)]}}],null,true)}),_c('Progress',{attrs:{"cargando":_vm.cargando}}),_c('DialogDetallesFull',{attrs:{"dialog":_vm.dialog,"nombreComponente":_vm.nombreComponente,"imagenesDetalles":_vm.imagenesDetalles},on:{"update-cerrar":_vm.update}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }