<template>
  <div class="ServicioDeImm">
    <v-row no-gutters class="mt-12">
      <v-spacer></v-spacer>
      <v-col cols="12" lg="8" sm="12" md="12">
        <v-card class="cardMaquinado" flat>
          <v-row
            style="padding-left: 2rem; padding-right: 2rem; padding-top: 2rem"
          >
            <p class="texto1">• Ingeniería en manejo de materiales •</p>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <p class="texto2">
              Algunas de las soluciones que ponemos a tu disposición son:
            </p>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
        <br />

        <v-card style="margin-top: 3rem" flat>
          <v-card-text>
            <v-row>
              <v-spacer></v-spacer>
              <v-card style="margin-left: 5rem; margin-right: 5rem" flat>
                <div class="cubo1 cubo">
                  <div class="triangulo1 triangulo"></div>
                  <div class="circulo1 circulo"></div>
                  <p class="textoCubo textoCubo1">
                    Equipos de automatizacion y manipulacion
                  </p>
                </div>
              </v-card>
              <v-card style="margin-left: 5rem; margin-right: 5rem" flat>
                <div class="cubo2 cubo">
                  <div class="triangulo2 triangulo"></div>
                  <div class="circulo2 circulo"></div>
                  <p class="textoCubo textoCubo2">Movimiento lineal</p>
                </div>
              </v-card>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-spacer></v-spacer>
              <v-card style="margin-left: 5rem; margin-right: 5rem" flat>
                <div class="cubo3 cubo">
                  <div class="triangulo3 triangulo"></div>
                  <div class="circulo3 circulo"></div>
                  <p class="textoCubo textoCubo3">
                    Transportadores en todas las variedades
                  </p>
                </div>
              </v-card>

              <v-img
                class="hidden-sm-and-down"
                lazy-src="@/assets/PrecargaLazzy.svg"
                max-height="270"
                max-width="470"
                src="/img/rueda.svg"
              ></v-img>

              <v-card style="margin-left: 5rem; margin-right: 5rem" flat>
                <div class="cubo4 cubo">
                  <div class="triangulo4 triangulo"></div>
                  <div class="circulo4 circulo"></div>
                  <p class="textoCubo textoCubo4">Equipos perifericos</p>
                </div>
              </v-card>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row>
          <v-spacer></v-spacer>
          <v-img
            class="hidden-md-and-up"
            lazy-src="@/assets/PrecargaLazzy.svg"
            max-height="270"
            max-width="470"
            src="/img/rueda.svg"
          ></v-img>
          <v-spacer></v-spacer>
        </v-row>

        <br />

        <v-row style="margin-top: 6rem">
          <v-spacer></v-spacer>
          <p class="text-h2">Brochure</p>
          <v-spacer></v-spacer>
        </v-row>

        <br />
        <v-row style="margin-bottom: 10rem !important">
          <v-spacer></v-spacer>
          <router-link to="/catalogo" class="mx-4 catalogo">
            <v-row>
              <v-btn class="mx-4" fab dark small color="#002655">
                <v-icon dark> mdi-download </v-icon>
              </v-btn>
              <p class="texto3">Te invitamos a descargar nuestro brochure</p>
            </v-row>
          </router-link>
          <v-spacer></v-spacer>
        </v-row>

        <v-row>
          <v-spacer></v-spacer>
          <v-img
            lazy-src="@/assets/PrecargaLazzy.svg"
            :width="imagenWidth"
            :src="imagenCalidad"
          ></v-img>
          <v-spacer></v-spacer>
        </v-row>

        <v-row style="margin-bottom: 2rem; margin-top: 3rem">
          <v-spacer></v-spacer>
          <BotonCorreo></BotonCorreo>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>
<script>
import BotonCorreo from "@/components/BotonCorreo.vue";
export default {
  name: "ServicioDeImm",

  components: {
    BotonCorreo,
  },

  data: () => ({
    //
  }),
  computed: {
    imagenCalidad() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "/img/calidadMedium.svg";
        case "sm":
          return "/img/calidadMedium.svg";
        case "md":
          return "/img/calidadMedium.svg";
        case "lg":
          return "/img/calidadGrande.svg";
        case "xl":
          return "/img/calidadGrande.svg";
      }
    },

    imagenWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300";
        case "sm":
          return "300";
        case "md":
          return "500";
        case "lg":
          return "800";
        case "xl":
          return "800";
      }
    },
  },
};
</script>

<style>
.cardMaquinado {
  border-left: 5px solid #002655 !important;
  background-color: #dddddd !important;
  margin-top: 1rem !important;
}

.texto1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  margin: 2rem;
}

.texto2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}

.texto3 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
}

.catalogo {
  text-decoration: none;
  color: black !important;
}

.catalogo:hover .texto3 {
  border-bottom: #002655 solid 2px !important;
  cursor: pointer;
}

.cubo {
  width: 10rem;
  height: 10rem;
  transform-origin: center center;
  border-radius: 50% !important;
}

.cubo:hover {
  animation: 0.5s slidein;
}

.circulo {
  position: absolute;
  top: 0.5rem !important;
  right: 0.5rem !important;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
}

.triangulo {
  position: absolute;
  width: 4rem;
  height: 4.5rem;
}

.textoCubo {
  position: absolute;
  color: white;
  font-size: 1rem;
  font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
}

.cubo1 {
  background-color: #fe9b09 !important;
}

.circulo1 {
  background: linear-gradient(to bottom right, #fe9b09, #ffcd46);
}

.triangulo1 {
  top: 5rem;
  right: 0.5rem;
  background-color: #fe9b09;
}

.textoCubo1 {
  top: 2.8rem;
  left: 1.5rem;
}

.cubo2 {
  background-color: #ff5158 !important;
}

.circulo2 {
  background: linear-gradient(to top right, #ff5158, #990000);
}

.triangulo2 {
  top: 5rem;
  left: 0.5rem;
  background-color: #ff5158;
}

.textoCubo2 {
  top: 3.5rem;
  left: 2.5rem;
}

.cubo3 {
  background-color: #3289e8 !important;
}
.circulo3 {
  background: linear-gradient(to bottom right, #3289e8, #1e9fd2);
}
.triangulo3 {
  top: 5rem;
  right: 0.5rem;
  background-color: #3289e8;
}

.textoCubo3 {
  top: 2.8rem;
  left: 1.5rem;
}

.cubo4 {
  background-color: #02b49c !important;
}

.circulo4 {
  background: linear-gradient(to bottom right, #02b49c, #1dbc9e);
}

.triangulo4 {
  top: 5rem;
  left: 0.5rem;
  background-color: #02b49c;
}

.textoCubo4 {
  top: 3.5rem;
  left: 2.7rem;
}

@keyframes slidein {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-30deg);
  }

  50% {
    transform: rotate(5deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
</style>