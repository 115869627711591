<template>
  <div class="Footer">
    <v-footer padless>
      <v-card class="flex" flat>
        <v-card-title class="cardBlue">
          <v-card class="flex cardBlue" flat>
            <v-card-text>
              <v-card class="flex text-h6 text-center cardBlue" flat>
                <v-icon left large dark> mdi-card-account-mail </v-icon>
                Contacto
              </v-card>
            </v-card-text>
          </v-card>

          <v-card class="flex cardBlue" flat>
            <v-card-text class="pt-1 white--text">
              <v-icon left dark> mdi-home </v-icon>
              Conveyors IMMSA Mexico S.A. de C.V
            </v-card-text>
            <v-card-text class="pt-0 white--text">
              <v-icon left dark> mdi-map-marker </v-icon>
              Lic. Víctor J, Víctor Jose Lizardi #109,
              <br />
              Valle del Real, 38024 Celaya, Gto.
            </v-card-text>

            <v-card-text class="pt-0 pb-1 white--text">
              <v-icon left dark> mdi-phone </v-icon>
              (461) 132-32-81
              <br />
              <v-icon left dark> mdi-email </v-icon>
              ventas@conveyors-immsa.com
            </v-card-text>
          </v-card>

          <v-card class="flex cardBlue" flat>
            <v-card-title>
              <v-spacer></v-spacer>

              <v-btn
                v-for="icon in icons"
                :key="icon.id"
                @click="redesSociales(icon)"
                class="mx-4"
                dark
                icon
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="40px" v-bind="attrs" v-on="on">
                      {{ icon.icono }}
                    </v-icon>
                  </template>
                  <span>{{ icon.tooltip }}</span>
                </v-tooltip>
              </v-btn>
            </v-card-title>
            <v-card-title class="text-center">
              <v-spacer></v-spacer>
              <v-icon left dark> mdi-thumb-up </v-icon>
              <strong> Encuéntranos en las redes sociales! </strong>
            </v-card-title>
          </v-card>
        </v-card-title>

        <v-card-text class="py-2 text-right">
          {{ new Date().getFullYear() }} —
          <strong>Conveyors IMMSA Mexico S.A. de C.V®</strong> — Todos los
          derechos reservados
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "Footer",

  data: () => ({
    //tooltip
    icons: [
      {
        id: 1,
        icono: "mdi-facebook",
        tooltip: "Facebook",
        link: "https://www.facebook.com/IngenieriaEnManejoDeMateriales",
      },
      {
        id: 2,
        icono: "mdi-linkedin",
        tooltip: "Linkedin",
        link: "https://www.linkedin.com/company/conveyors-immsa-mexico-s-a-de-c-v/mycompany/",
      },
      {
        id: 3,
        icono: "mdi-youtube",
        tooltip: "Youtube",
        link: "https://www.youtube.com/channel/UC-c91MorhwqbEz1D_lXAq_Q",
      },
      {
        id: 4,
        icono: "mdi-pinterest",
        tooltip: "Pinterest",
        link: "https://www.pinterest.it/brenlumendoza/",
      },
    ],
  }),

  methods: {
    redesSociales(icon) {
      window.open(icon.link);
    },
  },
};
</script>

<style>
.cardBlue {
  color: white !important;
  padding: 0 !important;
  background-color: #002655 !important;
}
</style>
