<template>
  <div class="Contacto">
    <v-container class="mt-6">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-col cols="12" xl="9" lg="10" md="12">
          <v-row>
            <v-col cols="12" md="3">
              <v-card class="flex" flat>
                <div style="background-color: #00000005">
                  <h4>Ponte en contacto con nosotros<span></span></h4>
                  <br />
                  <ul>
                    <label><strong> Telefono : </strong></label>
                    <p>(461) 132-32-81</p>

                    <label><strong> Correo: </strong></label>
                    <p>ventas@conveyors-immsa.com</p>

                    <label><strong> Direccion : </strong></label>
                    <p>
                      Lic. Víctor J, Víctor Jose Lizardi #109, Valle del Real,
                      38024 Celaya, Gto.
                    </p>
                  </ul>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="9">
              <v-card class="flex" flat>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3736.502860313299!2d-100.84980468576349!3d20.526596210108522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842cbb0a11d35235%3A0xd554e1d678a37a4d!2sCONVEYORS%20IMMSA%20MEXICO%20S.A.%20DE%20C.V.!5e0!3m2!1ses-419!2smx!4v1574438259144!5m2!1ses-419!2smx"
                  width="100%"
                  height="500"
                  frameborder="0"
                  style="border: 0"
                  allowfullscreen
                ></iframe>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="9">
              <h4>
                Si requieres información sobre nuestros servicios, puedes llenar
                el siguiente formulario y nosotros nos pondremos en contacto
                contigo.
              </h4>
              <br />
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card class="flex" flat>
                  <v-row>
                    <v-card class="flex" flat>
                      <v-text-field
                        class="cajas"
                        v-model="nombre"
                        dense
                        label="Nombre"
                        outlined
                        :rules="rules"
                      >
                      </v-text-field>
                    </v-card>
                    <v-card class="flex" flat>
                      <v-text-field
                        class="cajas"
                        v-model="correo"
                        dense
                        label="Correo"
                        outlined
                        :rules="emailRules"
                      >
                      </v-text-field>
                    </v-card>
                  </v-row>
                  <v-row>
                    <v-textarea
                      class="cajas"
                      outlined
                      v-model="mensaje"
                      name="input-7-4"
                      label="Mensaje"
                      :rules="rules"
                    >
                    </v-textarea>
                  </v-row>
                </v-card>
              </v-form>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="#002550"
                  class="ma-2 white--text"
                  @click="envioFormulario"
                >
                  Enviar
                  <v-icon right dark> mdi-email </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green" dark @click="envioWatsapp">
                  <v-icon left dark> mdi-whatsapp </v-icon>
                  WhatsApp
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
          </v-row>
          <br />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <!---------Mensaje de exito-------------->
      <v-alert
        type="success"
        v-model="successMessage"
        style="position: fixed; top: 150px"
        >Mensaje enviado, nos comunicaremos al correo {{ correo }}
      </v-alert>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Contacto",

  components: {},

  data: () => ({
    // mensaje correo
    valid: true,
    loading: false,

    //mensaje de exito
    successMessage: false,

    nombre: "",
    correo: "",
    mensaje: "",

    //Las reglas
    rules: [(v) => !!v || "Dato requerido"],
    emailRules: [
      (v) => !!v || "Dato requerido",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Correo no valido",
    ],
  }),

  mounted() {
    if (localStorage.nombre) {
      this.nombre = localStorage.nombre;
    }
     if (localStorage.correo) {
      this.correo = localStorage.correo;
    }
     if (localStorage.mensaje) {
      this.mensaje = localStorage.mensaje;
    }
  },
  watch: {
    nombre(newName) {
      localStorage.nombre = newName;
    },
    correo(newName) {
      localStorage.correo = newName;
    },
    mensaje(newName) {
      localStorage.mensaje = newName;
    },
  },

  methods: {
    envioFormulario() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        axios
          .get(
            "/php/envioCorreo.php?email=" +
              this.correo +
              "&subject=" +
              this.nombre +
              "&message=" +
              this.mensaje
          )
          .then(function (response) {
            //console.log(response);
            if (response.status == "200") {
              app.successMessage = true;
              setTimeout(() => {
                app.successMessage = false;
                app.loading = false;
                app.correo = "";
                app.nombre = "";
                app.mensaje = "";
              }, 2500);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    envioWatsapp() {
      window.open("https://wa.me/5200000000?text=Me%20interesa%20saber%20mas");
    },
  },
};
</script>

<style scoped>
.cajas {
  margin: 1rem !important;
}
</style>
